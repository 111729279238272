import React, { lazy, Suspense, useEffect,useState } from "react";

import Loading from "components/shared-components/Loading";

import { APP_PREFIX_PATH } from "configs/AppConfig";

import allLazyImports from './lazyImport/imports';

import ProtectedRoute from '../app-views/routes/protectedRoute/protectedRoute';



export const AppViews = ({userRole}) => {
  const roles=JSON.parse(localStorage.getItem('permissions'));
  const userPermissions=roles;
  const {Welcome,Notifications,
    ChartAccount,Voucher,VoucherDetails,VoucherAdd,Journal,
    TrailBalance,Ledger,AccountPayroll,PendingDetails,ProcessingDetails,SettledDetails,
    ExchangeRate,AccountingRequest,AccountingRequestDetails,BalanceSheet,IncomeExpenseReport,Notes,ExchangeGainLoss,FinancialYear,Office,
    Currency,Bank,BankAccount,Supplier,VoucherSignature,Opportunity,OpportunityAdd,OpportunityEdit,
    OpportunityDetails,ProjectManagement,ProjectDetails,Sector,Donors,PeopleTargetedCategories,Users,
    UserRoles,ProsEmployee,ProsEmployeeAdd,ProsEmployeeEdit,ProsEmployeeAddContract,Employee,
    EmployeeEdit,ContractAdd,ContractDetails,ContractEdit,TerminateContract,Attendance,
    DetailsPending,DetailsValidated,Payroll,PayrollPending,PayrollGenerated,PayrollExchangeRate,
    Recruitment,AddRecruitment,DetailsRecruitment,EditRecruitment,InterviewForm,Degree,
    Designation,Document,EmployeeGrade,Profession,FieldOfStudy,Leave,WorkingHours,Category,
    EmploymentType,Requests,AddRequests,RequestDetails,PurchaseOrders,PurchaseOrdersDetails,Vendors,AddVendors,DetailsVendor,
    DocumentType,VendorsCategory,ConsumableMasterStoreItem,ConsumableStoreItem,ConsumableStoreItemDetails,
    ExpendableMasterStoreItem,ExpendableStoreItemNames,ExpendableGNR,ExpendableStoreItem,ExpandableStoreItemsDetails,ExpendableAddStoreItem,
    NonExpendableMasterStoreItem,NonExpendableStoreItemNames,NonExpendableGNR,NonAddStoreItem,NonExpandableStoreItems,NonExpandableStoreItemsDetails,
    EmpIssuenceReg,InventoryRequest,InventoryRequestDetails,Unit,AssetType,ReceiptType,Depreciation,Status,Supplyrawmaterials,SupplyrawmaterialsAdd,
    SupplyrawmaterialsDetails,SupplyShippments,SupplyShippmentDetails,Items,AddItem,EditItem,
    Grade,AddGrade,EditGrade,SupplierChain,AddSupplierChain,EditSupplierChain,ItemQrCode,AddItemQrCode,
    EditItemQrCode,ProductDesign,ProductDesignAdd,ProductDesignEdit,Languages,EditLanguages,AddLanguages} = allLazyImports;
 
 

  const SupplyShippmentAdd=lazy(()=>import('./admin/supplyChain/shippments/shipments_details/shipments_add_details'));
 
  const SupplyPackaging=lazy(()=>import('./admin/supplyChain/packaging/packaging'));
  const SupplyPackagingAdd=lazy(()=>import('./admin/supplyChain/packaging/packaging_details/packaging_add_details'));
  const SupplyPackagingDetails=lazy(()=>import('./admin/supplyChain/packaging/packaging_details/packaging_detail_details'));
 


  return (
    <Suspense fallback={<Loading cover="content" />}>

      <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}`}
          component={Welcome}
          userPermissions={['welc']}
          allowedPermissions={['welc']}
          userRole={userRole}
        />
{/* supply chain */}
      {/* rawmaterials */}
<ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/rawmaterials`}
          component={Supplyrawmaterials}
          userPermissions={userPermissions}
          allowedPermissions={['viewRawMaterials']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/rawmaterials/add`}
          component={SupplyrawmaterialsAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addRawMaterials']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/rawmaterials/details/:id`} 
          component={SupplyrawmaterialsDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewRawMaterialsDetails']}
          userRole={userRole}
        />

        {/* shippments */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/shipments`}
          component={SupplyShippments}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyShippments']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/shipments/add`}
          component={SupplyShippmentAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addSupplyShippments']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/shipments/details/:id`} 
          component={SupplyShippmentDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyShippmentsDetails']}
          userRole={userRole}
        />
        {/* packaging */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/packaging`}
          component={SupplyPackaging}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyPackaging']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/packaging/add`}
          component={SupplyPackagingAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addSupplyPackaging']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/packaging/details/:id`} 
          component={SupplyPackagingDetails}
          userPermissions={userPermissions}
          allowedPermissions={['detailsSupplyPackaging']}
          userRole={userRole}
        />
{/* Supply chain configuration */}

<ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainprodcutDesign`}
          component={ProductDesign}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyChainProductDesign']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainprodcutDesign/add`}
          component={ProductDesignAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addProdcutDesign']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainprodcutDesign/edit/:id`}
          component={ProductDesignEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editProdcutDesign']}
          userRole={userRole}
        />

<ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainitemqrcode`}
          component={ItemQrCode}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyChainItemsQrcode']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainitemqrcode/add`}
          component={AddItemQrCode}
          userPermissions={userPermissions}
          allowedPermissions={['addItemsQrCode']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainitemqrcode/edit/:id`}
          component={EditItemQrCode}
          userPermissions={userPermissions}
          allowedPermissions={['editItemsQrCode']}
          userRole={userRole}
        />

<ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/items`}
          component={Items}
          userPermissions={userPermissions}
          allowedPermissions={['viewItems']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/items/add`}
          component={AddItem}
          userPermissions={userPermissions}
          allowedPermissions={['addItems']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/items/edit/:id`}
          component={EditItem}
          userPermissions={userPermissions}
          allowedPermissions={['editItems']}
          userRole={userRole}
        />

{/* grade config */}


        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplygrade`}
          component={Grade}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyChainGrade']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplygrade/add`}
          component={AddGrade}
          userPermissions={userPermissions}
          allowedPermissions={['addSupplyChainGrade']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplygrade/edit/:id`}
          component={EditGrade}
          userPermissions={userPermissions}
          allowedPermissions={['editSupplyChainGrade']}
          userRole={userRole}
        />

        {/* supply chain supplier */}


        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainsupplier`}
          component={SupplierChain}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplyChainSupplier']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainsupplier/add`}
          component={AddSupplierChain}
          userPermissions={userPermissions}
          allowedPermissions={['addSupplyChainSupplier']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplychainsupplier/edit/:id`}
          component={EditSupplierChain}
          userPermissions={userPermissions}
          allowedPermissions={['editSupplyChainSupplier']}
          userRole={userRole}
        />


        {/* system configuration  */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/languages`}
          component={Languages}
          userPermissions={userPermissions}
          allowedPermissions={['viewLanguages']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/langauges/add`}
          component={AddLanguages}
          userPermissions={userPermissions}
          allowedPermissions={['addLanguages']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/language/edit/:id`}
          component={EditLanguages}
          userPermissions={userPermissions}
          allowedPermissions={['editLanguages']}
          userRole={userRole}
        />
        {/* Notification center */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/notifcations`}
          component={Notifications}
          userPermissions={userPermissions}
          allowedPermissions={['viewNotification']}
          userRole={userRole}
        />
        {/* Users Management */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/roles`}
          component={UserRoles}
          userPermissions={userPermissions}
          allowedPermissions={['viewRole']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/users`}
          component={Users}
          userPermissions={userPermissions}
          allowedPermissions={['viewUser']}
          userRole={userRole}
        />
      {/* Opportunity */}
      <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/opportunity`}
          component={Opportunity}
          userPermissions={userPermissions}
          allowedPermissions={['viewOpportunity']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/opportunity/edit/:id`} 
          component={OpportunityEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editOpportunity']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/opportunity/details/:id`} 
          component={OpportunityDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewOpportunityDetails']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/opportunity/add`} 
          component={OpportunityAdd}
          userPermissions={userPermissions}
          allowedPermissions={['createOpportunity']}
          userRole={userRole}
        />

      {/* Opportunity End */}

        {/* Project Management */}

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/projectManagement`} 
          component={ProjectManagement}
          userPermissions={userPermissions}
          allowedPermissions={['viewProject']}
          userRole={userRole}
        />

         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/projectManagement/projectDetails/:id`}  
          component={ProjectDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewProjectDetails']}
          userRole={userRole}
        />
        {/* Jobs start */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/jobs`}  
          component={Jobs}
          userPermissions={userPermissions}
          allowedPermissions={['viewProjectJob']}
          userRole={userRole}
        /> */}
         
    

        {/* Project Management Configuration */}
        {/* Sector */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/sector`}
          component={Sector}
          userPermissions={userPermissions}
          allowedPermissions={['viewSector']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/sector/add`} 
          component={SectorAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addSector']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/sector/edit/:id`} 
          component={SectorEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editSector']}
          userRole={userRole}
        /> */}
        {/* Donors */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/donors`}
          component={Donors}
          userPermissions={userPermissions}
          allowedPermissions={['viewDonor']}
          userRole={userRole}
        />
         {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/donors/add`} 
          component={AddDonor}
          userPermissions={userPermissions}
          allowedPermissions={['addDonor']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/donors/edit/:id`} 
          component={EditDonor}
          userPermissions={userPermissions}
          allowedPermissions={['editDonor']}
          userRole={userRole}
        /> */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/peopleTargetedCategories`} 
          component={PeopleTargetedCategories}
          userPermissions={userPermissions}
          allowedPermissions={['viewPeopleTargetedCategory']}
          userRole={userRole}
        />

         {/* Project Management End */}


        {/* Accounting */}

            {/* Chart of accounts */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/chartAccounts`}   
          component={ChartAccount}
          userPermissions={userPermissions}
          allowedPermissions={['viewChartOfAccounts']}
          userRole={userRole}
        />
        {/* Vouchers */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/vouchers`} 
          component={Voucher}
          userPermissions={userPermissions}
          allowedPermissions={['viewVoucher']}
          userRole={userRole}
        />
         {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/voucher/edit/:id`} 
          component={VoucherEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editVoucher']}
          userRole={userRole}
        /> */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/voucher/details/:id`} 
          component={VoucherDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewVoucherTransaction']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/voucher/add`} 
          component={VoucherAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addVoucher']}
          userRole={userRole}
        />
         {/* Transaction */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/transactions`} 
          component={Transaction}
          userPermissions={userPermissions}
          allowedPermissions={['viewVoucherTransaction']}
          userRole={userRole}
        /> */}
          {/* Journal */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/journal`}  
          component={Journal}
          userPermissions={userPermissions}
          allowedPermissions={['viewJournal']}
          userRole={userRole}
        />
         {/* Trail Balance */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/trialbalance`}  
          component={TrailBalance}
          userPermissions={userPermissions}
          allowedPermissions={['viewTrialBalance']}
          userRole={userRole}
        />
         {/* Payroll */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/payroll`}  
          component={AccountPayroll}
          userPermissions={userPermissions}
          allowedPermissions={['viewFinancePendingPayroll']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/payroll/detailsPending/:id`}  
          component={PendingDetails}
          userPermissions={userPermissions}
          allowedPermissions={['financePendingPayrollDetails']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/payroll/detailsProcessing/:id`}  
          component={ProcessingDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewFinancePendingPayroll']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/accounting/payroll/detailsSettled/:id`}  
          component={SettledDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewFinancePendingPayroll']}
          userRole={userRole}
        />
        {/* ledger */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/ledger`}  
          component={Ledger}
          userPermissions={userPermissions}
          allowedPermissions={['viewLedger']}
          userRole={userRole}
        />
         {/* Exchange Rates */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/exchangeRate/office`} 
          component={ExchangeRate}
          userPermissions={userPermissions}
          allowedPermissions={['viewExchangeRates']}
          userRole={userRole}
        />

        {/* Accounting Request */}
          <ProtectedRoute
            exact
            path={`${APP_PREFIX_PATH}/accountingRequests`} 
            component={AccountingRequest}
            userPermissions={userPermissions}
            allowedPermissions={['viewAccountingRequest']}
            userRole={userRole}
            />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/accountingRequests/details/:id`} 
          component={AccountingRequestDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewAccountingRequestItem']}
          userRole={userRole}
        />

        {/* Accounting Financial Reports */}
         {/* BalanceSheet */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/BalanceSheet`}
          component={BalanceSheet}
          userPermissions={userPermissions}
          allowedPermissions={['viewBalanceSheet']}
          userRole={userRole}
        />
        {/* incomeExpenseReport */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/incomeExpenseReport`}
          component={IncomeExpenseReport}
          userPermissions={userPermissions}
          allowedPermissions={['viewIncomeAndExpenseReport']}
          userRole={userRole}
        />
         {/* Notes */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/notes`}
          component={Notes}
          userPermissions={userPermissions}
          allowedPermissions={['viewNotes']}
          userRole={userRole}
        />
        {/* Accounting Configuration */}
           {/* Financial Year */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/financialYear`} 
          component={FinancialYear}
          userPermissions={userPermissions}
          allowedPermissions={['viewFinancialYear']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/financialYear/add`}
          component={FinancialYearAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addFinancialYear']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/financialYear/edit/:id`}
          component={FinancialYearEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editFinancialYear']}
          userRole={userRole}
        /> */}
        {/* Gain & Loss */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/exchangeGainLoss`} 
          component={ExchangeGainLoss}
          userPermissions={userPermissions}
          allowedPermissions={['viewExchangeGainAndLossAccount']}
          userRole={userRole}
        />
        {/* Office */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/office`} 
          component={Office}
          userPermissions={userPermissions}
          allowedPermissions={['viewOffice']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/office/add`} 
          component={OfficeAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addOffice']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/office/edit/:id`}
          component={OfficeEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editOffice']}
          userRole={userRole}
        /> */}
          {/* Currency  */}
          <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/currency`} 
          component={Currency}
          userPermissions={userPermissions}
          allowedPermissions={['viewCurrency']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/currency/add`} 
          component={AddCurrency}
          userPermissions={userPermissions}
          allowedPermissions={['addCurrency']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/currency/edit/:id`}
          component={EditCurrency}
          userPermissions={userPermissions}
          allowedPermissions={['editCurrency']}
          userRole={userRole}
        /> */}
        {/* Bank */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bank`} 
          component={Bank}
          userPermissions={userPermissions}
          allowedPermissions={['viewBank']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bank/add`} 
          component={AddBank}
          userPermissions={userPermissions}
          allowedPermissions={['addBank']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bank/edit/:id`} 
          component={EditBank}
          userPermissions={userPermissions}
          allowedPermissions={['editBank']}
          userRole={userRole}
        /> */}
        
        {/* Bank Account */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bankAccount`} 
          component={BankAccount}
          userPermissions={userPermissions}
          allowedPermissions={['viewBankAccount']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bankAccount/add`} 
          component={AddBankAccount}
          userPermissions={userPermissions}
          allowedPermissions={['addBankAccount']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/bankAccount/edit/:id`} 
          component={EditBankAccount}
          userPermissions={userPermissions}
          allowedPermissions={['editBankAccount']}
          userRole={userRole}
        /> */}

        {/* supplier */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplier`} 
          component={Supplier}
          userPermissions={userPermissions}
          allowedPermissions={['viewSupplier']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplier/add`} 
          component={AddSupplier}
          userPermissions={userPermissions}
          allowedPermissions={['addSupplier']}
          userRole={userRole}
        /> */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/supplier/edit/:id`} 
          component={EditSupplier}
          userPermissions={userPermissions}
          allowedPermissions={['editSupplier']}
          userRole={userRole}
        /> */}

         {/* Accounting End */}

        {/* Human Resource  */}
          {/* employee  */}
          <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/prospective_employee`} 
          component={ProsEmployee}
          userPermissions={userPermissions}
          allowedPermissions={['viewProspectiveEmployee']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/prosEmployee/add`} 
          component={ProsEmployeeAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addProspectiveEmployee']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/prosEmployee/edit/:id`}  
          component={ProsEmployeeEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editProspectiveEmployee']}
          userRole={userRole}
        />

         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/prosEmployee/contract/add/:id`}  
          component={ProsEmployeeAddContract}
          userPermissions={userPermissions}
          allowedPermissions={['editProspectiveEmployee']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/employee`} 
          component={Employee}
          userPermissions={userPermissions}
          allowedPermissions={['viewEmployee']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/employee/add`} 
          component={EmployeeAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addEmployee']}
          userRole={userRole}
        /> */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/employee/edit/:id`}  
          component={EmployeeEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editEmployee']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/add/:id`}  
          component={ContractAdd}
          userPermissions={userPermissions}
          allowedPermissions={['addContract']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/details/:id/:contractId`}  
          component={ContractDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewContract']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/edit/:id/:contractId`}  
          component={ContractEdit}
          userPermissions={userPermissions}
          allowedPermissions={['editContract']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/terminateContract/:id/:contractId`}  
          component={TerminateContract}
          userPermissions={userPermissions}
          allowedPermissions={['viewContract']}
          userRole={userRole}
        />
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/editTerminateContract/:id/:contractId`}  
          component={TerminateContractEdit}
          userPermissions={userPermissions}
          allowedPermissions={['viewContract']}
          userRole={userRole}
        />
          */}
        {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/contract/editStandbyEmployeeContract/:id/:contractId`}  
          component={StandbyEmployeeContractEdit}
          userPermissions={userPermissions}
          allowedPermissions={['viewContract']}
          userRole={userRole}
        /> */}
        {/* Attendance */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/attendance`}  
          component={Attendance}
          userPermissions={userPermissions}
          allowedPermissions={['viewPendingAttendance']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/attendance/detailsPending/:attendanceId`}  
          component={DetailsPending}
          userPermissions={userPermissions}
          allowedPermissions={['viewPendingAttendanceDetails']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/attendance/detailsValidated/:attendanceId`}  
          component={DetailsValidated}
          userPermissions={userPermissions}
          allowedPermissions={['viewValidatedAttendanceDetails']}
          userRole={userRole}
        />
        {/* payroll */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/payroll`}  
          component={Payroll}
          userPermissions={userPermissions}
          allowedPermissions={['viewPendingPayroll']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/pendingPayroll/payrollDetails/:pendingId`}  
          component={PayrollPending}
          userPermissions={userPermissions}
          allowedPermissions={['pendingPayrollDetails']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/generatedPayroll/payrollDetails/:generatedId`}  
          component={PayrollGenerated}
          userPermissions={userPermissions}
          allowedPermissions={['generatedPayrollDetails']}
          userRole={userRole}
        />
        {/* Payroll Exchange Rate*/}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/payroll_exchange_rate/office`}  
          component={PayrollExchangeRate}
          userPermissions={userPermissions}
          allowedPermissions={['viewPayrollExchangeRates']}
          userRole={userRole}
        />
         {/*Recruitment*/}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/recruitment`}  
          component={Recruitment}
          userPermissions={userPermissions}
          allowedPermissions={['viewRecruitment']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/recruitment/add`}  
          component={AddRecruitment}
          userPermissions={userPermissions}
          allowedPermissions={['addRecruitment']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/recruitment/details/:recruitmentId`}  
          component={DetailsRecruitment}
          userPermissions={userPermissions}
          allowedPermissions={['viewRecruitment']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/recruitment/edit/:recruitmentId`}  
          component={EditRecruitment}
          userPermissions={userPermissions}
          allowedPermissions={['editRecruitment']}
          userRole={userRole}
        />


          <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/recruitment/details/:recruitmentId/:applicationId/interview`}  
          component={InterviewForm}
          userPermissions={userPermissions}
          allowedPermissions={['viewInterviewForm']}
          userRole={userRole}
        />
        
        {/* Human Resource Configuration */}
           {/* Degree  */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/degree`} 
          component={Degree}
          userPermissions={userPermissions}
          allowedPermissions={['viewDegree']}
          userRole={userRole}
        />
        {/* Designation  */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/designation`} 
          component={Designation}
          userPermissions={userPermissions}
          allowedPermissions={['viewDesignation']}
          userRole={userRole}
        />
       {/* Document Type  */}
      <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/documentType`} 
          component={Document}
          userPermissions={userPermissions}
          allowedPermissions={['viewDocumentType']}
          userRole={userRole}
        />
         {/* Employee Grade  */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/employeeGrade`} 
          component={EmployeeGrade}
          userPermissions={userPermissions}
          allowedPermissions={['viewGrade']}
          userRole={userRole}
        />
        {/* Profession  */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/profession`} 
          component={Profession}
          userPermissions={userPermissions}
          allowedPermissions={['viewProfession']}
          userRole={userRole}
        />
        {/* Field  */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/fieldOfStudy`} 
          component={FieldOfStudy}
          userPermissions={userPermissions}
          allowedPermissions={['viewFieldOfStudy']}
          userRole={userRole}
        />
        {/* Leave */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/leave`} 
          component={Leave}
          userPermissions={userPermissions}
          allowedPermissions={['viewLeave']}
          userRole={userRole}
        />
        {/* WorkingHours */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/workingHours`} 
          component={WorkingHours}
          userPermissions={userPermissions}
          allowedPermissions={['viewWorkingHour']}
          userRole={userRole}
        />
         {/* Category */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/category`} 
          component={Category}
          userPermissions={userPermissions}
          allowedPermissions={['viewCategory']}
          userRole={userRole}
        />
         {/* EmploymentType */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/employmentType`} 
          component={EmploymentType}
          userPermissions={userPermissions}
          allowedPermissions={['viewEmploymentType']}
          userRole={userRole}
        />
        {/* Human Resource End */}


        {/* Inventory */}

         {/* Consumable */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/masterStoreItem`} 
          component={ConsumableMasterStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['viewMasterStoreItem']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/storeItems`} 
          component={ConsumableStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['viewStoreItem']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/storeItems/details/:id`} 
          component={ConsumableStoreItemDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewStoreItemDetails']}
          userRole={userRole}
        />
        {/* Expandable */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/expendableMasterStoreItem`} 
          component={ExpendableMasterStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['viewExpendableMasterStoreItem']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/expendableStoreItemNames`} 
          component={ExpendableStoreItemNames}
          userPermissions={userPermissions}
          allowedPermissions={['viewExpendableMasterStoreItem']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/expendableGNR`} 
          component={ExpendableGNR}
          userPermissions={userPermissions}
          allowedPermissions={['viewExpendableMasterStoreItem']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/expendableStoreItems`} 
          component={ExpendableStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['viewExpendableStoreDetails']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/exStoreDetails/add`} 
          component={ExpendableAddStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['addExpendableStoreDetails']}
          userRole={userRole}
        />
       
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/exStoreDetails/details/:id`} 
          component={ExpandableStoreItemsDetails}
          userPermissions={userPermissions}
          allowedPermissions={['editExpendableStoreDetails']}
          userRole={userRole}
        />

        {/* NonExpandable */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nonExpMasterStoreItem`} 
          component={NonExpendableMasterStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['viewNonExpendableMasterStoreItem']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nonExpendableStoreItemNames`} 
          component={NonExpendableStoreItemNames}
          userPermissions={userPermissions}
          allowedPermissions={['viewNonExpendableMasterStoreItem']}
          userRole={userRole}
        />

      <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nonExpendableGNR`} 
          component={NonExpendableGNR}
          userPermissions={userPermissions}
          allowedPermissions={['viewNonExpendableMasterStoreItem']}
          userRole={userRole}
        />

         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nExStoreDetails`} 
          component={NonExpandableStoreItems}
          userPermissions={userPermissions}
          allowedPermissions={['viewNonExpendableStoreDetails']}
          userRole={userRole}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nExStoreDetails/add`} 
          component={NonAddStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['addNonExpendableStoreDetails']}
          userRole={userRole}
        />
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nExStoreDetails/details/:id`}  
          component={NonExpandableStoreItemsDetails}
          userPermissions={userPermissions}
          allowedPermissions={['editNonExpendableStoreDetails']}
          userRole={userRole}
        />
         {/* <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/nExStoreDetails/edit/:id`}  
          component={NonEditStoreItem}
          userPermissions={userPermissions}
          allowedPermissions={['editNonExpendableStoreDetails']}
          userRole={userRole}
        /> */}
        
         {/* EmpIssuenceReg */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/EmpIssuenceReg`}  
          component={EmpIssuenceReg}
          userPermissions={userPermissions}
          allowedPermissions={['viewGeneralInventoryItems']}
          userRole={userRole}
        />

        {/* Inventory Request */}
        <ProtectedRoute
            exact
            path={`${APP_PREFIX_PATH}/inventoryRequests`} 
            component={InventoryRequest}
            userPermissions={userPermissions}
            allowedPermissions={['viewInventoryRequest']}
            userRole={userRole}
            />

          <ProtectedRoute
            exact
            path={`${APP_PREFIX_PATH}/inventoryRequests/details/:id`} 
            component={InventoryRequestDetails}
            userPermissions={userPermissions}
            allowedPermissions={['viewInventoryRequestItem']}
            userRole={userRole}
            />
        
        {/* Inventory Configuration */}
        {/* Unit */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/unit`} 
          component={Unit}
          userPermissions={userPermissions}
          allowedPermissions={['viewUnit']}
          userRole={userRole}
        />
        {/* Asset Type */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/assetType`} 
          component={AssetType}
          userPermissions={userPermissions}
          allowedPermissions={['viewAssetType']}
          userRole={userRole}
        />
         {/* Receipt Type */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/receiptType`} 
          component={ReceiptType}
          userPermissions={userPermissions}
          allowedPermissions={['viewReceiptType']}
          userRole={userRole}
        />
        {/* Depreciation */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/depreciation`} 
          component={Depreciation}
          userPermissions={userPermissions}
          allowedPermissions={['viewDepreciation']}
          userRole={userRole}
        />
         {/* Status */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/status`} 
          component={Status}
          userPermissions={userPermissions}
          allowedPermissions={['viewStatus']}
          userRole={userRole}
        />
        {/* Inventory End */}

        {/* Voucher Signature */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/signature`} 
          component={VoucherSignature}
          userPermissions={userPermissions}
          allowedPermissions={['viewVoucherSignature']}
          userRole={userRole}
        />

        {/* Procurement */}
       {/* Requests */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/requests`} 
          component={Requests}
          userPermissions={userPermissions}
          allowedPermissions={['viewRequest']}
          userRole={userRole}
        />

          <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/requests/add`} 
          component={AddRequests}
          userPermissions={userPermissions}
          allowedPermissions={['addRequest']}
          userRole={userRole}
        />

        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/requests/details/:id`} 
          component={RequestDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewRequestItem']}
          userRole={userRole}
        />

        {/* Purchase Orders */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/purchaseOrders`} 
          component={PurchaseOrders}
          userPermissions={userPermissions}
          allowedPermissions={['viewOrders']}
          userRole={userRole}
        />

          <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/purchaseOrders/details/:id`} 
          component={PurchaseOrdersDetails}
          userPermissions={userPermissions}
          allowedPermissions={['viewOrderItems']}
          userRole={userRole}
        />
        
        {/* Vendors */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/vendors`} 
          component={Vendors}
          userPermissions={userPermissions}
          allowedPermissions={['viewVendor']}
          userRole={userRole}
        />

        {/* Add Vendor */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/add_vendors`} 
          component={AddVendors}
          userPermissions={userPermissions}
          allowedPermissions={['addVendor']}
          userRole={userRole}
        />

        {/* Vendor Category */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/vendors_category`} 
          component={VendorsCategory}
          userPermissions={userPermissions}
          allowedPermissions={['viewVendorCategory']}
          userRole={userRole}
        />

         {/* Document Type */}
         <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/document_type`} 
          component={DocumentType}
          userPermissions={userPermissions}
          allowedPermissions={['viewVendorDocumentType']}
          userRole={userRole}
        />

        {/* Details */}
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/vendors/details/:id`}   
          component={DetailsVendor}
          userPermissions={userPermissions}
          allowedPermissions={['editVendor']}
          userRole={userRole}
        />

    </Suspense>
  );
};

export default React.memo(AppViews);